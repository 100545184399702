.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    display: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-link{
    display: flex;
    gap: 4rem;
}

.social-link>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img{
    /* width: 10rem; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}

.blur-f-2{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}

/* .fa {
    padding: 20px;
    font-size: 20px;
    width: 20px;
    text-align: center;
    text-decoration: none;
    margin: 5px 4px; 
     border-radius: 5px;
} */

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
/* background: #3B5998; */
    color: #3B5998;
    font-size: 3.5em;
    width: 24px !important;
    height: 24px !important;
}

.fa-instagram {
    /* background: #8a3ab9; */
    color:  rgba(193, 53, 132);
    font-size: 3.5em;
    width: 24px !important;
    height: 24px !important;
  }