.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.image {
    width: 1000px;
    height: 800px;
    border-radius: 10px;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}


@media screen and (max-width: 768px) {
    .slider {
        /* position: absolute;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
        height: auto;
    }
}